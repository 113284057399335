(function() {
    'use strict';

    angular.module('adminApp')
        .controller("PropertiesTabCtrl", PropertiesTabController);

    PropertiesTabController.$inject = ["$scope", "aerosAdminApi", "createNewSystemPropertyModal", "$uibModal",
        "Notification"];

    function PropertiesTabController($scope, aerosAdminApi, createNewSystemPropertyModal, $uibModal, Notification) {

        $scope.$watch("commonReady", function (newVal) {
            if (!newVal) return;

            if (!$scope.allow['editSystemProperties']) return $scope.reject();
            // init
            loadProperties();
            loadTypes();

            function loadProperties() {
                aerosAdminApi.loadProperties().success(function(data) {
                    $scope.properties = _.sortBy(data, function(o){
                        return o.category?o.category.toLowerCase():"";
                    });
                });
            }

            function loadTypes() {
                aerosAdminApi.loadSystemPropertyTypes().success(function(data) {
                    $scope.types = data;
                });
            }

            $scope.createNewProperty = function() {
                createNewSystemPropertyModal.open(undefined, $scope.types).then(function (property) {
                    aerosAdminApi.saveProperty(property).success(function (data) {
                        loadProperties();
                        Notification.success("Property Overridden.");
                    });
                });
            };

            $scope.deleteProperty = function(property) {
                $uibModal.open({
                    size: "md",
                    resolve: {
                        name: function () {
                            return property.type;
                        }
                    },
                    templateUrl: 'confirmDeleteModal.html',
                    controller: ["$scope", "name", function($scope, name){
                        $scope.title = name;
                    }]
                }).result.then(function () {
                    return aerosAdminApi.deleteProperty(property.id).then(function(data) {
                        loadProperties();
                        Notification.success("Property '" + property.type + "' Reset to default.");
                    });
                });
            };

            $scope.editProperty = function (property) {
                createNewSystemPropertyModal.open(property, $scope.types).then(function (property) {
                    aerosAdminApi.saveProperty(property).success(function (data) {
                        loadProperties();
                        Notification.success("Property '" + property.type + "' updated.");
                    });
                });
            };
        });
    }

})();

